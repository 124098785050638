import React from "react";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const About = () => {
  return (
    <Box sx={{ padding: "20px", textAlign: "center" }}>
      <InfoIcon sx={{ fontSize: 60, color: "#1DB954", marginBottom: "20px" }} />
      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1DB954", marginBottom: "20px" }}>
        Sobre o Best Trainer
      </Typography>
      <Typography variant="body1" sx={{ color: "#555555", maxWidth: "600px", margin: "0 auto" }}>
        O Best Trainer foi criado para ajudar pessoas de todos os níveis a alcançar seus objetivos fitness. Nossa
        plataforma oferece treinos personalizados, ferramentas práticas como calculadoras de calorias e dicas valiosas
        para otimizar sua performance e saúde. Estamos aqui para transformar sua jornada fitness!
      </Typography>
    </Box>
  );
};

export default About;
