import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import CalculateIcon from "@mui/icons-material/Calculate";

const Calculator = () => {
  const [calories, setCalories] = useState(0);
  const [weight, setWeight] = useState("");
  const [time, setTime] = useState("");

  const calculateCalories = () => {
    const result = weight * time * 0.1; // Fórmula simplificada
    setCalories(result.toFixed(2));
  };

  return (
    <Box sx={{ padding: "20px", textAlign: "center" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1DB954", marginBottom: "20px" }}>
        Calculadora de Calorias
      </Typography>
      <CalculateIcon sx={{ fontSize: 60, color: "#1DB954", marginBottom: "20px" }} />
      <Box sx={{ maxWidth: "400px", margin: "0 auto", textAlign: "left" }}>
        <TextField
          label="Peso (kg)"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: "20px" }}
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
        <TextField
          label="Tempo de Exercício (min)"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: "20px" }}
          value={time}
          onChange={(e) => setTime(e.target.value)}
        />
        <Button
          variant="contained"
          color="success"
          fullWidth
          sx={{ padding: "10px" }}
          onClick={calculateCalories}
        >
          Calcular
        </Button>
      </Box>
      {calories > 0 && (
        <Typography
          variant="h5"
          sx={{
            marginTop: "20px",
            backgroundColor: "#f4f4f4",
            padding: "15px",
            borderRadius: "10px",
            fontWeight: "bold",
            display: "inline-block",
          }}
        >
          Calorias Queimadas: {calories}
        </Typography>
      )}
    </Box>
  );
};

export default Calculator;
