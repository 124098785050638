import React from "react";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

const Workouts = () => {
  const workouts = [
    { title: "Aquecimento Matinal", description: "Prepare seu corpo para o dia com exercícios rápidos." },
    { title: "Treino de Abdominais", description: "Fortaleça o core com exercícios intensos." },
    { title: "Cardio para Iniciantes", description: "Melhore seu condicionamento com este treino básico." },
    { title: "HIIT Avançado", description: "Queime calorias com treinos de alta intensidade." },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1DB954", marginBottom: "20px", textAlign: "center" }}>
        Treinos Disponíveis
      </Typography>
      <Grid container spacing={4}>
        {workouts.map((workout, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                textAlign: "center",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <DirectionsRunIcon sx={{ fontSize: 50, color: "#1DB954" }} />
              <CardContent>
                <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                  {workout.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "#777777" }}>
                  {workout.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Workouts;
