import React from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CalculateIcon from "@mui/icons-material/Calculate";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import InfoIcon from "@mui/icons-material/Info";

const Home = () => {
  const features = [
    { title: "Treinos", icon: <FitnessCenterIcon fontSize="large" />, link: "/workouts" },
    { title: "Calculadora", icon: <CalculateIcon fontSize="large" />, link: "/calculator" },
    { title: "Dicas", icon: <TipsAndUpdatesIcon fontSize="large" />, link: "/tips" },
    { title: "Sobre", icon: <InfoIcon fontSize="large" />, link: "/about" },
  ];

  return (
    <Box sx={{ textAlign: "center", padding: "20px" }}>
      {/* Título Principal */}
      
      <Typography variant="body1" sx={{ marginBottom: "40px", color: "#555555" }}>
        Explore nossos recursos para alcançar seus objetivos fitness!
      </Typography>

      {/* Grade Responsiva */}
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={6} sm={3} key={index} sx={{ textAlign: "center" }}>
            <IconButton
              href={feature.link}
              sx={{
                backgroundColor: "#f4f4f4",
                borderRadius: "12px",
                padding: "20px",
                "&:hover": { backgroundColor: "#1DB954", color: "#ffffff" },
              }}
            >
              {feature.icon}
            </IconButton>
            <Typography variant="body1" sx={{ marginTop: "10px", fontWeight: "bold" }}>
              {feature.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Home;
