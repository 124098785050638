import React from "react";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

const Tips = () => {
  const tips = [
    "Beba pelo menos 2 litros de água por dia.",
    "Durma 7-8 horas por noite para recuperação muscular.",
    "Evite pular refeições para manter a energia.",
    "Inclua proteínas em todas as suas refeições.",
    "Faça alongamentos antes e depois dos treinos.",
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1DB954", marginBottom: "20px", textAlign: "center" }}>
        Dicas Fitness
      </Typography>
      <Grid container spacing={4}>
        {tips.map((tip, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                textAlign: "center",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <LightbulbIcon sx={{ fontSize: 50, color: "#1DB954" }} />
              <CardContent>
                <Typography variant="body1" sx={{ color: "#777777" }}>
                  {tip}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Tips;
