import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./Navigation";
import Home from "./pages/Home";
import Workouts from "./pages/Workouts";
import Calculator from "./pages/Calculator";
import Tips from "./pages/Tips";
import About from "./pages/About";
import { Box } from "@mui/material";

const App = () => {
  return (
    <Router>
      <div>
        {/* Menu de navegação */}
        <Navigation />

        {/* Container centralizado com margens */}
        <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/workouts" element={<Workouts />} />
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/tips" element={<Tips />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Box>
      </div>
    </Router>
  );
};

export default App;
