import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";

const Navigation = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  return (
    <>
      {/* Barra superior */}
      <AppBar position="static" sx={{ backgroundColor: "#1DB954" }}>
        <Toolbar>
          {/* Ícone de menu para abrir o Drawer */}
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>

          {/* Título/logo */}
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
            Best Trainer
          </Typography>

          {/* Botão Voltar */}
          <IconButton color="inherit" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer (menu lateral) */}
      <Drawer anchor="left" open={drawerOpen} onClose={() => toggleDrawer(false)}>
        <Box sx={{ width: 250, display: "flex", flexDirection: "column", height: "100%" }}>
          {/* Logo no topo */}
          <Box sx={{ textAlign: "center", padding: "20px 0", backgroundColor: "#f4f4f4" }}>
            <img
              src="https://lp.besttrainer.com.br/static/media/logo-best.e52c381e4a26c660f527.png"
              alt="Best Trainer Logo"
              style={{ width: "150px" }}
            />
          </Box>

          {/* Menu */}
          <List sx={{ flexGrow: 1 }}>
            <ListItem button component={Link} to="/" onClick={() => toggleDrawer(false)}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/workouts" onClick={() => toggleDrawer(false)}>
              <ListItemText primary="Treinos" />
            </ListItem>
            <ListItem button component={Link} to="/calculator" onClick={() => toggleDrawer(false)}>
              <ListItemText primary="Calculadora" />
            </ListItem>
            <ListItem button component={Link} to="/tips" onClick={() => toggleDrawer(false)}>
              <ListItemText primary="Dicas" />
            </ListItem>
            <ListItem button component={Link} to="/about" onClick={() => toggleDrawer(false)}>
              <ListItemText primary="Sobre" />
            </ListItem>
          </List>

          {/* Rodapé com versão do app */}
          <Box sx={{ padding: "10px", textAlign: "center", backgroundColor: "#f4f4f4" }}>
            <Typography variant="caption" sx={{ color: "#777777" }}>
              Versão 1.0.0
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Navigation;
